import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setLoadingOff, setLoadingOn } from "../../state/dashboard/mainSlice";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../service/dashboard/AuthService";
import { StatusCodes } from "http-status-codes";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../../service/dashboard/ConsultantRegistrationService";
import MessageDialog from "../../component/MessageDialog";
// import GeneralTermsDialog from "../../component/GeneralTermsDialog";
import {
    readProfile,
    readUserRanks,
} from "../../service/dashboard/ProfileService";
import {
    replaceArabicZeterMonthToRoman,
    replaceShortRanks,
} from "../../util/stringFormat";
import { setUserProfile, setUserRanks } from "../../state/dashboard/userSlice";
import oppBannerSquare from "../../asset/opp_partner_benefits_square.png";
import oppBannerWide from "../../asset/opp_partner_benefits_wide.png";
import {
    clearCart,
    clearProductOrder,
    setProductList,
} from "../../state/dashboard/webshopSlice";
import { readAllProducts } from "../../service/dashboard/webshop/ProductService";

const ConsultantRegistration: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("consultantregistration"));

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    let navigate = useNavigate();
    setAuthToken();
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            accepted: false,
        },
        validationSchema: Yup.object({
            //TODO backend banszámla további ellenőrzés
            accepted: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
        }),
        onSubmit: (values) => {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Online Partener regisztráció folyamatban.",
                })
            );
            // setFormData(values);
            //alert(JSON.stringify(values, null, 2));
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            // TODO ha current user e-mail nem üres, redux
            register(values, getCurrentUser().email).then(
                (response) => {
                    // console.log(response);
                    formik.resetForm();
                    setMessageDialogError(false);
                    setMessageDialogHeader("Gratulálunk!");
                    setMessageDialogBody(
                        "Ön sikeresen Online Partnerré vált!" +
                            " Partner száma: " +
                            response.data.representativeNumber
                    );
                    readProfile(getCurrentUser().email).then(
                        (response) => {
                            dispatch(setUserProfile(response.data));
                            // console.log(response.data);
                            readUserRanks(
                                response.data.representativeNumber
                            ).then(
                                (response) => {
                                    // console.log(response.data.rankList);
                                    response.data.rankList.map((rank: any) => {
                                        rank.zepterMonth =
                                            replaceArabicZeterMonthToRoman(
                                                rank.zepterMonth
                                            );
                                        rank.rank = replaceShortRanks(
                                            rank.rank
                                        );
                                        rank.fromDate =
                                            rank.fromDate.split(" ")[0] + ".";
                                        rank.toDate =
                                            rank.toDate.split(" ")[0] + ".";
                                        return rank;
                                    });
                                    dispatch(
                                        setUserRanks(
                                            response.data.rankList.reverse()
                                        )
                                    );
                                    // console.log(response.data);
                                    //product list komponensből átköltöztetve
                                    readAllProducts().then(
                                        (response) => {
                                            // console.log(response);
                                            dispatch(
                                                setProductList(
                                                    response.data.productList
                                                )
                                            );
                                            dispatch(setLoadingOff());
                                        },
                                        (error) => {
                                            console.log(error.response);
                                        }
                                    );
                                },
                                (error) => {
                                    // console.log(error.response.status);
                                    if (
                                        error &&
                                        error.response &&
                                        error.response.status ===
                                            StatusCodes.UNAUTHORIZED
                                    ) {
                                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                                        logout();
                                        dispatch(setLoadingOff());
                                        navigate("/login?status=unauthorized");
                                    }
                                }
                            );
                        },
                        (error) => {
                            // console.log(error.response.status);
                            if (
                                error &&
                                error.response &&
                                error.response.status &&
                                error.response.status ===
                                    StatusCodes.UNAUTHORIZED
                            ) {
                                //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                                logout();
                                dispatch(setUserProfile({}));
                                dispatch(setUserRanks([]));
                                dispatch(setLoadingOff());
                                navigate("/login?status=unauthorized");
                            } else if (
                                error &&
                                error.response &&
                                error.response.status
                            ) {
                                logout();
                                dispatch(setUserProfile({}));
                                dispatch(setUserRanks([]));
                                dispatch(setLoadingOff());
                                navigate("/login?status=connectionerror");
                            }
                        }
                    );
                    //TODO ellenőrizni
                    setMessageDialogNavigateAction("../productlist");
                    setMessageDialogShow(Date.now());
                    dispatch(clearCart());
                    dispatch(clearProductOrder());
                    dispatch(setLoadingOff());
                },
                (error) => {
                    // console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                    const responseMessage =
                        (error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessageDialogError(true);
                    setMessageDialogHeader("Regisztrációs hiba!");
                    setMessageDialogBody(responseMessage);
                    setMessageDialogNavigateAction("");
                    setMessageDialogShow(Date.now());
                    dispatch(setLoadingOff());
                }
            );
        },
    });

    return (
        <>
            <MessageDialog
                messageDialogError={messageDialogError}
                messageDialogShow={messageDialogShow}
                messageDialogHeader={messageDialogHeader}
                messageDialogBody={messageDialogBody}
                messageDialogNavigateAction={messageDialogNavigateAction}
            />
            {/* <GeneralTermsDialog /> */}
            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
                <div className="surface-card border-round shadow-2 pb-2 pl-2 pr-2 pt-4 md:p-4 lg:p-5">
                    <div className="flex align-items-center justify-content-center">
                        <img
                            className="max-w-full mb-3 border-round-xl fadeinleft animation-duration-500 md:hidden"
                            src={oppBannerSquare}
                            alt="Zepter statisztikák"
                        />
                        <img
                            className="hidden max-w-full mb-3 border-round-xl fadeinleft animation-duration-500 md:block"
                            src={oppBannerWide}
                            alt="Zepter statisztikák"
                        />
                    </div>
                    <div className="text-xl text-900 font-medium mb-3 ml-2">
                        Online Partner regisztráció
                    </div>
                    {userProfile &&
                    ((userProfile.vatNumber && userProfile.vatNumber !== "") ||
                        (userProfile.companyName &&
                            userProfile.companyName !== "") ||
                        (userProfile.companyType &&
                            userProfile.companyType !== "")) ? (
                        <div className="mb-5 flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                            <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                            <div className="mr-3">
                                <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">
                                    Információ
                                </div>
                                <p className="m-0 p-0 text-yellow-700 line-height-3">
                                    A{" "}
                                    <a
                                        href="https://bizzclub.hu/main/mybasicsconsultantgeneraltermsandconditions220615"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        ZepterClub hűségprogram tanácsadói
                                        tevékenység ellátására vonatkozó
                                        általános szerződési feltételek
                                    </a>{" "}
                                    2.1-es pontja értelmében,{" "}
                                    <strong>
                                        kizárólag magányszemély válhat Online
                                        Partnerré
                                    </strong>
                                    .<br />
                                    <br />
                                    Kérjük magánszemélyként jelentkezzen be a
                                    bizzclub.hu felületre és válassza az "Online
                                    Partner regisztráció" menüpontot.
                                    <br />
                                    <br />
                                    Amennyiben a vásárlásai után meg szeretné
                                    adni a profiljához tartozó céges számlázási
                                    adatokat, kérjük írjon{" "}
                                    <a href="mailto:koordinacio@zepter.hu?subject=Klubtags&aacute;gi sz&aacute;ml&aacute;z&aacute;si adatok m&oacute;dos&iacute;t&aacute;si k&eacute;r&eacute;s">
                                        koordinacio@zepter.hu
                                    </a>{" "}
                                    e-mail címre.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <p className="ml-2">
                                Szeretném élvezni a Zepterrel kapcsolatos még
                                szorosabb kapcsolat előnyeit és az Online
                                Partneri státuszhoz kapcsolódó kedvezmények
                                rendszerét.
                            </p>
                            <form
                                onSubmit={formik.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="field col-12 md:col-12">
                                    <div className="field-checkbox col">
                                        <Checkbox
                                            id="accepted"
                                            name="accepted"
                                            checked={formik.values.accepted}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="accepted">
                                            {/* //TODO change */}
                                            <a
                                                href="https://bizzclub.hu/main/mybasicsconsultantgeneraltermsandconditions220615"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                ZepterClub hűségprogram
                                                tanácsadói tevékenység
                                                ellátására vonatkozó általános
                                                szerződési feltételeket
                                            </a>{" "}
                                            megismertem és elfogadom.
                                        </label>
                                        {formik.touched.accepted &&
                                        formik.errors.accepted ? (
                                            <small className="p-error">
                                                {formik.errors.accepted}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="field col-12 md:col-12">
                                    <Button
                                        label="Online Partnerré válok"
                                        className="w-full"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ConsultantRegistration;
