import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { ProgressBar } from "primereact/progressbar";
import { RootState } from "../../state/store";
import { formatPhoneNumber } from "../../util/stringFormat";
import pageTeaserProfileImage from "../../asset/page-teaser-profile.png";

const Profile: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("profile"));

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    return (
        <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
            <div className="surface-card border-round shadow-2 p-2 md:p-4 lg:p-5">
                <div className="mb-2 flex justify-content-center">
                    <div className="w-auto md:w-8">
                        <img
                            src={pageTeaserProfileImage}
                            className="responsive fadeinleft animation-duration-500"
                            alt="bizzclub.hu profil kép"
                        />
                    </div>
                </div>
                <div className="text-xl text-900 font-medium mb-3 ml-2">
                    Profil adataim
                </div>
                {userProfile && userProfile.email ? (
                    <ul className="list-none p-0 m-0">
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Név
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.userName : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                ZepterClub tagsági szám
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.vipNumber : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                ZepterClub tagság kezdete
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? userProfile.vipRegistrationDate
                                    : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                ZepterClub ajánló
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? userProfile.recommenderName
                                    : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Tanácsadó szám
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? userProfile.representativeNumber
                                    : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Tanácsadói szerződés dátuma
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? userProfile.representativeRegistrationDate
                                    : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Menedzser
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.managerName : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                E-mail
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.email : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Cím
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.address : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Mobil
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? formatPhoneNumber(userProfile.mobile)
                                    : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Telefon
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.telephone : null}
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Adószám
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile ? userProfile.vatNumber : null}
                            </div>
                        </li>

                        {/* <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-6 md:w-3 font-medium">
                            Adóazonosító
                        </div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {userProfile ? userProfile.taxNumber : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-6 md:w-3 font-medium">
                            TAJ szám
                        </div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {userProfile ? userProfile.ssnNumber : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-6 md:w-3 font-medium">
                            Bankszámlaszám
                        </div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {userProfile ? userProfile.personalAccountNumber : null}
                        </div>
                    </li> */}
                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-500 w-6 md:w-3 font-medium">
                                Státusz
                            </div>
                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                {userProfile
                                    ? userProfile.registrationStatus
                                    : null}
                            </div>
                        </li>
                    </ul>
                ) : (
                    <>
                        <span>Profil adatok betöltése, kérem várjon</span>
                        <ProgressBar
                            className=" m-0 p-0 pt-0 md:pt-3 mr-2"
                            mode="indeterminate"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Profile;
