import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router";
import { setLoadingOff, setLoadingOn } from "../../state/dashboard/mainSlice";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setAuthToken } from "../../service/dashboard/AuthService";
import {
    createInstallmentPayment,
    readSalesContract,
} from "../../service/dashboard/InstallmentPaymentService";
import MessageDialog from "../../component/MessageDialog";
import MessageAlert from "../../component/MessageAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import pageTeaserInstallmentPayImage from "../../asset/page-teaser-installment-pay.png";

interface SalesContractType {
    salesContractNo: string;
    customerName: string;
    customerEmail: string;
    salesContractValue: number;
    payedAmount: number;
}

const initialSalesContract: SalesContractType = {
    salesContractNo: "",
    customerName: "",
    customerEmail: "",
    salesContractValue: 0,
    payedAmount: 0,
};

// const testSalesContract: SalesContractType = {
//     salesContractNo: "888888888",
//     customerName: "Teszt Elek",
//     customerEmail: "tesz@tesz.hu",
//     salesContractValue: 100000,
//     payedAmount: 20000,
// };

const InstallmentPayment: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("installmentpayment"));
    setAuthToken();

    // let navigate = useNavigate();

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const [salesContract, setSalesContract] =
        useState<SalesContractType>(initialSalesContract);
    const [totalDebt, setTotalDebt] = useState<number>(0);

    const [acceptSimplePayGtac, setAcceptSimplePayGtac] = useState(false);

    const formikPost = useFormik({
        initialValues: {
            requestType: "INSTALLMENT_PAYMENT",
            total: 1000,
            salesContractNo: "",
            customerEmail: "",
            customerName: "",
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: Yup.object({
            total: Yup.number()
                .min(1000, "A minimális fizetendő összeg 1.000 Ft.")
                .max(
                    totalDebt === 0 ? 10000000 : totalDebt,
                    "Maximálisan a hátralék összege fizethető."
                )
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Adatok küldése OTP SimplePay-nek.",
                })
            );
            const data = {
                ...values,
                total: values.total != null ? values.total.toString() : "",
            };
            // alert(JSON.stringify(data, null, 2));
            // console.log(data);
            createInstallmentPayment(data).then(
                (response) => {
                    console.log(response);
                    dispatch(setLoadingOff());
                    // navigate(response.data);
                    window.location.href = response.data;
                },
                (error) => {
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(responseMessage);
                    dispatch(setLoadingOff());
                }
            );
        },
    });

    const formikGet = useFormik({
        initialValues: {
            salesContractNo: "",
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: Yup.object({
            salesContractNo: Yup.string()
                .min(9, "A szerződésszám minimum 9 karakternek kell lenni!")
                .max(15, "A szerződésszám maximum 15 karakter hosszú!")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Szerződés keresés folyamatban.",
                })
            );
            readSalesContract(values.salesContractNo).then(
                (response) => {
                    // console.log(response);
                    dispatch(setLoadingOff());
                    if (
                        response &&
                        response.status &&
                        response.status === 200
                    ) {
                        setSalesContract(response.data);
                        setAcceptSimplePayGtac(false);
                        setTotalDebt(
                            response.data.salesContractValue -
                                response.data.payedAmount
                        );
                        formikPost.setFieldValue(
                            "salesContractNo",
                            response.data.salesContractNo
                                ? response.data.salesContractNo
                                : values.salesContractNo
                        );
                        //TODO ha az alábbiak üresek legyen beviteli mező
                        formikPost.setFieldValue(
                            "customerName",
                            response.data.customerName
                                ? response.data.customerName
                                : ""
                        );
                        formikPost.setFieldValue(
                            "customerEmail",
                            response.data.customerEmail
                                ? response.data.customerEmail
                                : "cl100@zepter.hu"
                        );
                        formikPost.setFieldValue("total", 1000);
                    }
                },
                (error) => {
                    setSalesContract(initialSalesContract);
                    setAcceptSimplePayGtac(false);
                    formikPost.setFieldValue("total", 1000);
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(responseMessage);
                    dispatch(setLoadingOff());
                    if (
                        responseMessage &&
                        responseMessage ===
                            "Request failed with status code 404"
                    ) {
                        setMessageDialogHeader("Értesítés");
                        setMessageDialogHeader("Nincs találat!");
                        setMessageDialogBody(
                            "A megadott szerződésszám nem található a Zepter Navision rendszerében."
                        );
                        setMessageDialogError(true);
                        setMessageDialogShow(Date.now());
                    } else {
                        setMessageDialogHeader("Értesítés");
                        setMessageDialogHeader("Hálozati hiba!");
                        setMessageDialogBody(
                            "Hiba történt a kommunikációban a szerződésszám lekérdezésénél."
                        );
                        setMessageDialogError(true);
                        setMessageDialogShow(Date.now());
                    }
                }
            );
        },
    });

    const renderButton = (percentage: number) => {
        const value = Math.ceil((totalDebt * percentage) / 100);
        if (value >= 1000) {
            return (
                <Button
                    label={`${percentage}%`}
                    onClick={() => formikPost.setFieldValue("total", value)}
                />
            );
        }
        return null;
    };

    return (
        <div className="w-auto">
            <MessageDialog
                messageDialogError={messageDialogError}
                messageDialogShow={messageDialogShow}
                messageDialogHeader={messageDialogHeader}
                messageDialogBody={messageDialogBody}
            />
            <div className="grid max-w-full mb-0 md:mb-3">
                <div className="col-12">
                    <MessageAlert />
                </div>
            </div>
            <div className="mb-3 md:mb-5 flex justify-content-center">
                <div className="w-auto">
                    <img
                        src={pageTeaserInstallmentPayImage}
                        className="responsive fadeinleft animation-duration-500"
                        alt="zepterclub.hu ZepterClub bankkártyás részletfizetés kép"
                    />
                </div>
            </div>
            <Panel className="z-0" header="Bankkártyás részletfizetés">
                <form
                    onSubmit={formikGet.handleSubmit}
                    className="p-fluid mb-4"
                >
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <label
                                htmlFor="salesContractNo"
                                className="block text-900 font-medium mb-2"
                            >
                                Szerződésszám
                            </label>
                            <InputText
                                className={
                                    formikGet.errors.salesContractNo
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                autoFocus
                                id="salesContractNo"
                                {...formikGet.getFieldProps("salesContractNo")}
                            />
                            {formikGet.errors.salesContractNo ? (
                                <small className="p-error">
                                    {formikGet.errors.salesContractNo}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="col-12">
                            <Button
                                icon="pi pi-search"
                                label="Keresés"
                                className="w-full mt-3"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
                {salesContract.salesContractNo !== "" ? (
                    <>
                        <Divider align="center">
                            <Badge
                                value={salesContract.salesContractNo}
                                severity="success"
                                size="xlarge"
                            />
                        </Divider>
                        <div className="grid justify-center">
                            <div className="sm:col-12 md:col-8 md:p-2 lg:col-7 lg:p-3 xl:col-6 xl:p-4">
                                <div className="inline-flex col-12 md-6">
                                    <label className="col-6 md-6 text-500 font-medium">
                                        Ügyfél neve:
                                    </label>
                                    <div className="col-6 md-6 text-right text-900">
                                        {salesContract.customerName}
                                    </div>
                                </div>
                                <div className="inline-flex col-12 md-6">
                                    <label className="col-6 md-6 text-500 font-medium">
                                        Szerződés értéke:
                                    </label>
                                    <div className="col-6 md-6 text-right text-900">
                                        {salesContract.salesContractValue.toLocaleString(
                                            "hu-HU"
                                        )}{" "}
                                        Ft
                                    </div>
                                </div>
                                <div className="inline-flex col-12 md-6">
                                    <label className="col-6 md-6 text-500 font-medium">
                                        Befizetett összeg:
                                    </label>
                                    <div className="col-6 md-6 text-right text-900">
                                        {salesContract.payedAmount.toLocaleString(
                                            "hu-HU"
                                        )}{" "}
                                        Ft
                                    </div>
                                </div>
                                <div className="inline-flex col-12 md-6">
                                    <label className="col-6 md-6 text-500 font-medium">
                                        Hátralék:
                                    </label>
                                    <div className="col-6 md-6 text-right text-900 font-bold text-pink-500">
                                        {(0 - totalDebt).toLocaleString(
                                            "hu-HU"
                                        )}{" "}
                                        Ft
                                    </div>
                                </div>
                            </div>
                        </div>
                        {totalDebt > 999 ? (
                            <>
                                <div className="card flex justify-content-center">
                                    <span className="p-buttonset">
                                        {renderButton(100)}
                                        {renderButton(50)}
                                        {renderButton(30)}
                                        {renderButton(10)}
                                    </span>
                                </div>
                                <form
                                    onSubmit={formikPost.handleSubmit}
                                    className="p-fluid mt-4"
                                >
                                    <div className="formgrid grid">
                                        <div className="field col-12">
                                            <label
                                                htmlFor="total"
                                                className="font-bold block mb-2"
                                            >
                                                Fizetendő összeg
                                            </label>
                                            <InputNumber
                                                className={
                                                    formikPost.errors.total
                                                        ? "w-full p-invalid"
                                                        : "w-full"
                                                }
                                                inputId="total"
                                                mode="currency"
                                                currency="HUF"
                                                locale="hu-HU"
                                                minFractionDigits={0}
                                                value={
                                                    !formikPost.values.total
                                                        ? 0
                                                        : formikPost.values
                                                              .total
                                                }
                                                onChange={(e) => {
                                                    let fieldValue = 0;
                                                    if (
                                                        e.value &&
                                                        e.value > totalDebt
                                                    ) {
                                                        fieldValue = totalDebt;
                                                        // } else if (
                                                        //     e.value &&
                                                        //     e.value < 1000
                                                        // ) {
                                                        //     fieldValue = 1000;
                                                    } else if (e.value) {
                                                        fieldValue = e.value;
                                                    }
                                                    formikPost.setFieldValue(
                                                        "total",
                                                        fieldValue
                                                    );
                                                }}
                                                onBlur={() =>
                                                    formikPost.validateField(
                                                        "total"
                                                    )
                                                }
                                                min={0}
                                                max={totalDebt}
                                                step={1000}
                                                showButtons
                                                buttonLayout="horizontal"
                                                decrementButtonClassName="p-button-danger"
                                                incrementButtonClassName="p-button-success"
                                                incrementButtonIcon="pi pi-plus"
                                                decrementButtonIcon="pi pi-minus"
                                            />
                                            {formikPost.errors.total ? (
                                                <small className="p-error">
                                                    {formikPost.errors.total}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            !acceptSimplePayGtac
                                                ? "formgrid grid border-1 border-pink-500 border-round-md p-2 m-0"
                                                : "formgrid grid border-1 border-green-500 border-round-md p-2 m-0"
                                        }
                                    >
                                        <div className="field-checkbox col-12 mb-0">
                                            <Checkbox
                                                id="accepted-simplepay"
                                                name="accepted-simplepay"
                                                checked={acceptSimplePayGtac}
                                                onChange={(e) => {
                                                    // console.log(e);
                                                    if (e.checked) {
                                                        setAcceptSimplePayGtac(
                                                            e.checked
                                                        );
                                                    } else {
                                                        setAcceptSimplePayGtac(
                                                            false
                                                        );
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor="accepted-simplepay"
                                                className="flex flex-wrap text-lg text-900 line-height-3 ml-3"
                                            >
                                                Tudomásul veszem, hogy a Zepter
                                                Ungarn Kft. (1138 Budapest, Váci
                                                út 191.) adatkezelő által a
                                                zepterclub.hu felhasználói
                                                adatbázisában tárolt alábbi
                                                személyes adataim átadásra
                                                kerülnek az OTP Mobil Kft., mint
                                                adatfeldolgozó részére. Az
                                                adatkezelő által továbbított
                                                adatok köre az alábbi: vásárló
                                                neve, e-mail címe, rendelés
                                                száma, fizetendő összeg
                                                <br />
                                                Az adatfeldolgozó által végzett
                                                adatfeldolgozási tevékenység
                                                jellege és célja a SimplePay
                                                Adatkezelési tájékoztatóban, az
                                                alábbi linken tekinthető meg:
                                                <br />
                                                <a
                                                    href="https://simplepay.hu/adatkezelesi-tajekoztatok/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    https://simplepay.hu/adatkezelesi-tajekoztatok/
                                                </a>
                                            </label>
                                        </div>
                                        {!acceptSimplePayGtac ? (
                                            <small className="p-error ml-2 mt-0">
                                                Bankkártyás fizetéshez el kell
                                                fogadni az OTP SimplePay
                                                adatkezelési tájékoztatót.
                                            </small>
                                        ) : null}
                                    </div>
                                    <p className="mt-4 text-teal-700">
                                        A befizetések munkanapokon 16 óráig
                                        történő beérkezése esetén a következő
                                        munkanapon kerülnek könyvelésre.
                                        Amennyiben a befizetés munkanapokon 16
                                        óra után, vagy hétvégén érkezik, úgy az
                                        következő két munkanapon belül kerül
                                        feldolgozásra.
                                    </p>
                                    <div className="formgrid grid">
                                        <div className="col-12">
                                            <Button
                                                icon="pi pi-credit-card"
                                                label="Befizetés"
                                                className="w-full mt-3"
                                                type="submit"
                                                disabled={
                                                    !acceptSimplePayGtac ||
                                                    (formikPost.errors.total !==
                                                        null &&
                                                        formikPost.errors
                                                            .total !==
                                                            undefined &&
                                                        formikPost.errors
                                                            .total !== "")
                                                }
                                            />
                                        </div>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <div className="max-w-full">
                                <div className="flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                                    <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                                    <div className="mr-3">
                                        <div className="m-0 p-0 text-green-700 line-height-3">
                                            A bankkártyás részletfizetés minimum
                                            1.000 Ft tartozás esetén
                                            használható.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </Panel>
        </div>
    );
};

export default InstallmentPayment;
