import React, { useState } from "react";
// import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getPassword } from "../../service/home/PasswordService";
import RightLayoutBenefits1 from "../../component/RightLayoutBenefits1";
import MessageDialog from "../../component/MessageDialog";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Login.css";
import pageTeaserGetPasswordImage from "../../asset/page-teaser-get-password.png";

const GetPassword: React.FC = () => {
    let [searchParams] = useSearchParams();
    const status: any = searchParams.get("status");

    // let navigate = useNavigate();
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const [blockedPage, setBlockedPage] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            accepted: false,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
            accepted: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
        }),
        onSubmit: (values) => {
            setBlockedPage(true);
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            //alert(JSON.stringify(values, null, 2));
            getPassword(values.email).then(
                (response) => {
                    setMessageDialogError(false);
                    setMessageDialogHeader("Értesítés");
                    setMessageDialogBody(
                        "Sikeresen elküldtük a jelszó beállításhoz szükséges linket, kérjük ellenőrizze az e-mail fiókját!"
                    );
                    //TODO ellenőrizni
                    setMessageDialogShow(Date.now());
                    setMessageDialogNavigateAction(
                        "/login?status=sentpasswordrequestemail"
                    );
                },
                (error) => {
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response.status === 404) {
                        setMessageDialogHeader("Regisztráció szükséges");
                        setMessageDialogBody(
                            "Sajnos a megadott e-mail cím nem szerepel a " +
                                "ZepterClub hűségprogram adatbázisában, így nem tudunk jelszót beállítani a fiókjához, " +
                                "új regisztráció szükséges. " +
                                "Regisztrációhoz kérjen meghívó linket bármely klubtagunktól vagy tanácsadónktól!"
                        );
                        setMessageDialogNavigateAction(
                            "../login?status=registrationrequired"
                        );
                    } else if (
                        error.response.status === 500 ||
                        responseMessage === "Network Error"
                    ) {
                        setMessageDialogHeader("Hiba");
                        setMessageDialogBody(
                            "Hálozati hiba, kérem ellenőrizze az internet kapcsolatot és próbálja újra."
                        );
                        setBlockedPage(false);
                        //TODO param átadni
                        // setMessageDialogNavigateAction("../login");
                    } else {
                        if (responseMessage === "Network Error") {
                            setMessageDialogBody(
                                "Hálozati hiba, kérem próbáljon meg pár perc múlva jelszót igényelni."
                            );
                        } else {
                            setMessageDialogBody(responseMessage);
                        }
                        //TODO param átadni
                        setMessageDialogNavigateAction("../login");
                    }
                    setMessageDialogError(true);
                    //TODO ellenőrizni
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                    messageDialogNavigateAction={messageDialogNavigateAction}
                />
                <BlockUI
                    blocked={blockedPage}
                    className="max-w-screen h-full z-1"
                    fullScreen
                    template={
                        <div
                            className="text-center text-teal-50"
                            style={{ fontSize: "2rem" }}
                        >
                            <i
                                className="pi pi-spin pi-spinner"
                                style={{ fontSize: "3rem" }}
                            />
                            <br />
                            E-mail küldése folyamatban, kérem várjon!
                        </div>
                    }
                />
                {status === "changepasswordlinkexpired" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                        <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">
                                Jelszó igénylő link lejárt!
                            </div>
                            <p className="m-0 p-0 text-yellow-700 line-height-3">
                                Kérjük a legfrissebb e-mailben küldött jelszó
                                módosító linket használja vagy kérjen egy újat.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "changepassworderror" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-red-100 border-round border-1 border-red-300">
                        <i className="pi pi-times-circle text-red-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-red-900 font-medium text-xl mb-2 line-height-1">
                                Hibás jelszó beállítás!
                            </div>
                            <p className="m-0 p-0 text-red-700 line-height-3">
                                Kérem igényeljen új jelszó módosító linket!
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap shadow-2">
                    <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                        <div className="col-12 md:col-6 lg:col-5">
                            <img
                                src={pageTeaserGetPasswordImage}
                                className="responsive fadeinleft animation-duration-500"
                                alt="bizzclub.hu jelszó igénylés kép"
                            />
                        </div>
                        <div className="col-12 md:col-6 lg:col-7">
                            <div className="text-xl text-black-alpha-90 font-500 mb-3 sm:mt-3">
                                ZepterClub jelszó igénylés
                            </div>
                            <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-3">
                                Kérjük adja meg e-mail címét ahova küldjük a
                                jelszó beállításhoz szükséges információkat!
                            </p>
                        </div>
                    </div>
                    <div className="mt-0 w-full p-4 surface-card lg:w-6 lg:p-7">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="p-fluid"
                        >
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label
                                        htmlFor="email"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Email
                                    </label>
                                    <InputText
                                        className="w-full mb-3"
                                        id="email"
                                        {...formik.getFieldProps("email")}
                                        onBlur={(val: any) => {
                                            const emailTrim =
                                                formik.values.email
                                                    .trim()
                                                    .toLocaleLowerCase();
                                            formik.setFieldValue(
                                                "email",
                                                emailTrim
                                            );
                                        }}
                                    />

                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                        <small className="p-error">
                                            {formik.errors.email}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <div className="field-checkbox col">
                                        <Checkbox
                                            id="accepted"
                                            name="accepted"
                                            checked={formik.values.accepted}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="accepted">
                                            <a
                                                href="https://www.zepter.hu/Rules/Regulation-BizzClub"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                ZepterClub hűségprogram
                                                szabályzatot
                                            </a>{" "}
                                            megismertem és elfogadom.
                                        </label>
                                    </div>
                                    {formik.touched.accepted &&
                                    formik.errors.accepted ? (
                                        <small className="p-error">
                                            {formik.errors.accepted}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <Button
                                        label="Küldés"
                                        className="w-full"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <RightLayoutBenefits1 />
                </div>
            </div>
        </>
    );
};

export default GetPassword;
